import React, { Component } from 'react';
import Layout from '../components/layout';
import HelmetEx from '../components/helmet-ex';
import { Link } from 'gatsby';
//imgs
import DesignAndDecoratingImage from '../images/icons/design-and-decorating-01.svg';
import FeaturedServicesImage from '../images/icons/featured-services-01.svg';
import InstallationServicesImage from '../images/icons/installation-services-01.svg';
import RepairServicesImage from '../images/icons/repair-services-01.svg';
import ServiceInformationImage from '../images/icons/service-information-01.svg';
import Config from '../config';

class Services extends Component {
    render() {
        const routes = Config.Services.ServiceRoutes;
        return (
            <Layout>
                <div className="services-page">
                    <HelmetEx>
                        <div metadata="title">Viktoria Interior | Professional Home Design & Decoration | Elmwood Park, NJ</div>
                        <div metadata="keywords">viktoria,interior-professional-home-design-decoration-bergen-county-NJ</div>
                        <div metadata="description">
                            Professional Home Decoration and Design in Elmwood Park, Elmwood Park, NJ
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="col-12 text-center">
                                        <h1>SERVICES</h1>
                                        <h3>Count on our professional staff for the finest in interior</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="services">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4 text-center col-12 col-md-4 pt-4 pt-lg-0">
                                    <img src={FeaturedServicesImage} alt="featured Services" />
                                    <h3 >FEATURED SERVICES</h3>
                                    <p>
                                        Count on our professional staff for the finest in interior
                                        design service - to suit any decorating style and any size project.
                                    </p>
                                    <Link to={routes.featuredServices} className="link-view-more">VIEW MORE</Link>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 text-center pt-4 pt-lg-0">
                                    <img src={DesignAndDecoratingImage} alt="design and decorating" />
                                    <h3 >DESIGN AND DECORATING</h3>
                                    <p >
                                        Count on our upholstery department to re-craft your favorite piece
                                        of furniture or create a new piece totally from scratch
                                    </p>
                                    <Link to={routes.designAndDecorating} className="link-view-more">VIEW MORE</Link>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 text-center pt-4 pt-lg-0">
                                    <img src={RepairServicesImage} alt="repair services" />
                                    <h3>REPAIR SERVICES</h3>
                                    <p>
                                        Count on us to repair your window coverings quickly and professionally
                                        - wheather you purchased from us or a from another dealer
                                    </p>
                                    <Link to={routes.repairService} className="link-view-more">VIEW MORE</Link>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 text-center margin-md-top-60px pt-4 pt-lg-0">
                                    <img src={InstallationServicesImage} alt="installation services" />
                                    <h3>INSTALLATION SERVICES</h3>
                                    <p>
                                        We help you set your decorating budget. Give us a call for a FREE EstimateI
                                    </p>
                                    <Link to={routes.installationService} className="link-view-more">VIEW MORE</Link>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 text-center margin-md-top-60px pt-4 pt-lg-0">
                                    <img src={ServiceInformationImage} alt="service information" />
                                    <h3>SERVICE INFORMATION</h3>
                                    <p >
                                        We help you set your decorating budget. Give us a call for a FREE Estimate
                                    </p>
                                    <Link to={routes.serviceInformation} className="link-view-more">VIEW MORE</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default Services; 